<template>
  <div id="inFirst">
    <el-dialog
      title="采购清单"
      :visible.sync="dialogTableVisible"
      custom-class="role-mask"
    >
      <!-- 表单 -->

      <!-- 表格 -->
      <!-- 表格 height="390"  固定高度-->
      <el-table
        ref="singleTable"
        :data="purchasingListDetail"
        tooltip-effect="dark"
        style="width: 100%"
        height="390"
        highlight-current-row
        @selection-change="handleCurrentChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="编号" width="120">
          <template slot-scope="scope">
            {{ scope.row.materialNo }}
          </template>
        </el-table-column>
        <el-table-column
          prop="materialName"
          label="名称"
          width="120"
        />
        <el-table-column
          prop="spec"
          label="型号规格"
          show-overflow-tooltip
        />
        <el-table-column prop="factory" label="厂家" />
        <el-table-column prop="quantity" label="数量" />
        <el-table-column prop="remarks" label="备注" />
      </el-table>

      <!-- 底部按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="handlerOk">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="inFirstFormTitle"
      width="680px"
      :visible.sync="inFirstDialogVisible"
      :close-on-click-modal="false"
      @close="inFirstDialogClose"
    >
      <el-form
        ref="inFirstFormRef"
        :model="inFirstForm"
        :rules="inFirstFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="内外销" prop="type">
              <el-radio-group
                v-model="inFirstForm.type"
                :disabled="inFirstFormTitle !== '新增入库初检记录'
                  && inFirstFormTitle !== '修改入库初检记录'
                  && inFirstFormTitle !== '入库初检记录详情'"
              >
                <el-radio
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.code"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="采购编号" prop="purchaseListNo">
              <el-select
                v-model="inFirstForm.purchaseListNo"
                placeholder="请选择物料"
                clearable
                filterable
                @change="purchaseListChange"
              >
                <el-option
                  v-for="item in purchasingList"
                  :key="item.id"
                  :label="item.purchaseListNo"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.purchaseListNo }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.generator
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编号" prop="materialNo">
              <el-input
                v-model="inFirstForm.materialNo"
                placeholder="请输入编号"
                clearable
                :disabled="
                  inFirstFormTitle !== '新增入库初检记录' &&
                    inFirstFormTitle !== '修改入库初检记录' &&
                    inFirstFormTitle !== '入库初检记录详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="名称" prop="name">
              <el-input
                v-model="inFirstForm.name"
                placeholder="请输入名称"
                clearable
                :disabled="
                  inFirstFormTitle !== '新增入库初检记录' &&
                    inFirstFormTitle !== '修改入库初检记录' &&
                    inFirstFormTitle !== '入库初检记录详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格型号" prop="spec">
              <el-input
                v-model="inFirstForm.spec"
                placeholder="请输入规格型号"
                clearable
                :disabled="
                  inFirstFormTitle !== '新增入库初检记录' &&
                    inFirstFormTitle !== '修改入库初检记录' &&
                    inFirstFormTitle !== '入库初检记录详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批号" prop="batchNo">
              <el-input
                v-model="inFirstForm.batchNo"
                placeholder="请输入批号"
                clearable
                :disabled="
                  inFirstFormTitle !== '新增入库初检记录' &&
                    inFirstFormTitle !== '修改入库初检记录' &&
                    inFirstFormTitle !== '入库初检记录详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="厂家" prop="manufacturer">
              <el-input
                v-model="inFirstForm.manufacturer"
                placeholder="请输入厂家"
                clearable
                :disabled="
                  inFirstFormTitle !== '新增入库初检记录' &&
                    inFirstFormTitle !== '修改入库初检记录' &&
                    inFirstFormTitle !== '入库初检记录详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="到货日期" prop="arrivalDate">
              <el-date-picker
                v-model="inFirstForm.arrivalDate"
                placeholder="请选择到货日期"
                value-format="yyyy-MM-dd"
                :disabled="
                  inFirstFormTitle !== '新增入库初检记录' &&
                    inFirstFormTitle !== '修改入库初检记录' &&
                    inFirstFormTitle !== '入库初检记录详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="初检日期" prop="firstDate">
              <el-date-picker
                v-model="inFirstForm.firstDate"
                placeholder="请选择初检日期"
                value-format="yyyy-MM-dd"
                :disabled="
                  inFirstFormTitle !== '新增入库初检记录' &&
                    inFirstFormTitle !== '修改入库初检记录' &&
                    inFirstFormTitle !== '入库初检记录详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="初检数量" prop="firstQuantity">
              <el-input
                v-model="inFirstForm.firstQuantity"
                placeholder="请输入初检数量"
                clearable
                :disabled="
                  inFirstFormTitle !== '新增入库初检记录' &&
                    inFirstFormTitle !== '修改入库初检记录' &&
                    inFirstFormTitle !== '入库初检记录详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 62.8px">
            <el-form-item label="外观验收情况" prop="checkSituation">
              <el-radio-group
                v-model="inFirstForm.checkSituation"
                :disabled="
                  inFirstFormTitle !== '新增入库初检记录' &&
                    inFirstFormTitle !== '修改入库初检记录' &&
                    inFirstFormTitle !== '入库初检记录详情'
                "
              >
                <el-radio :label="1">
                  符合规定
                </el-radio>
                <el-radio :label="2">
                  不符合规定
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="inFirstForm.remarks"
                placeholder="请输入备注"
                clearable
                :disabled="
                  inFirstFormTitle !== '新增入库初检记录' &&
                    inFirstFormTitle !== '修改入库初检记录' &&
                    inFirstFormTitle !== '入库初检记录详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核结果" prop="reviewerResult">
              <el-radio-group
                v-model="inFirstForm.reviewerResult"
                :disabled="
                  inFirstFormTitle !== '复核入库初检记录' &&
                    inFirstFormTitle !== '入库初检记录详情'
                "
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="inFirstDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="inFirstFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="名称">
        <el-input
          v-model="searchForm.name"
          placeholder="请输入名称"
          clearable
        />
      </el-form-item>
      <el-form-item label="内外销" prop="type">
        <el-select v-model="searchForm.type" placeholder="请选择内外销" clearable>
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['IN_FIRST_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="inFirstPage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="manufacturer" label="厂家" />
      <el-table-column label="到货日期">
        <template slot-scope="scope">
          <span v-if="scope.row.arrivalDate">{{
            scope.row.arrivalDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="初检日期">
        <template slot-scope="scope">
          <span v-if="scope.row.firstDate">{{
            scope.row.firstDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="firstQuantity" label="初检数量" />
      <el-table-column label="外观验收情况">
        <template slot-scope="scope">
          <span v-if="scope.row.checkSituation === 1">符合规定</span>
          <span v-if="scope.row.checkSituation === 2">不符合规定</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="checker" label="验收人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.checkerDate">{{
            scope.row.checkerDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerDate">{{
            scope.row.reviewerDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerResult === 1">通过</span>
          <span v-if="scope.row.reviewerResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column label="内外销">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">内销</span>
          <span v-if="scope.row.type === 2">外销</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="
              checkPermission(['IN_FIRST_DELETE']) && scope.row.status === 1
            "
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="
              checkPermission(['IN_FIRST_UPDATE']) && scope.row.status === 1
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="
              checkPermission(['IN_FIRST_REVIEW']) && scope.row.status === 1
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="inFirstPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addInFirst,
  deleteInFirst,
  updateInFirst,
  selectInFirstInfo,
  selectInFirstList,
  reviewInFirst
} from '@/api/storage/inFirst'
import { selectMaterialPurchaseStockList } from '@/api/storage/materialPurchaseStock'
import { getShoppingList } from '@/api/purchase/shoppingList'
import { selectDictList } from '@/api/system/dict'

export default {
  data () {
    return {
      inFirstDialogVisible: false,
      dialogTableVisible: false,
      inFirstFormTitle: '',
      inFirstForm: {
        id: '',
        name: '',
        purchaseListNo: '',
        materialNo: '',
        spec: '',
        batchNo: '',
        manufacturer: '',
        arrivalDate: '',
        firstDate: '',
        firstQuantity: '',
        checkSituation: '',
        remarks: '',
        reviewerResult: '',
        taskId: '',
        type: ''
      },
      inFirstFormRules: {
        name: [
          {
            required: true,
            message: '名称不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      inFirstPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: '',
        type: ''
      },
      //   materialPurchaseStockList: []
      purchasingList: [],
      purchasingListDetail: [],
      currentRow: '',
      typeList: []
    }
  },
  created () {
    selectInFirstList(this.searchForm).then((res) => {
      this.inFirstPage = res
    })
    // selectMaterialPurchaseStockList({ type: 1 }).then((res) => {
    //   this.materialPurchaseStockList = res.list
    // })
    getShoppingList().then((res) => {
      this.purchasingList = res.list
    })
    selectDictList(2).then(res => {
      this.typeList = res
    })
  },
  methods: {
    inFirstDialogClose () {
      this.$refs.inFirstFormRef.resetFields()
    },
    inFirstFormSubmit () {
      if (this.inFirstFormTitle === '入库初检记录详情') {
        this.inFirstDialogVisible = false
        return
      }
      this.$refs.inFirstFormRef.validate(async (valid) => {
        if (valid) {
          if (this.inFirstFormTitle === '新增入库初检记录') {
            await addInFirst(this.inFirstForm)
          } else if (this.inFirstFormTitle === '修改入库初检记录') {
            await updateInFirst(this.inFirstForm)
          } else if (this.inFirstFormTitle === '复核入库初检记录') {
            await reviewInFirst(this.inFirstForm)
          }
          this.inFirstPage = await selectInFirstList(this.searchForm)
          this.inFirstDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.inFirstFormTitle = '新增入库初检记录'
      this.inFirstDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteInFirst(row.id)
        if (this.inFirstPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.inFirstPage = await selectInFirstList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.inFirstFormTitle = '修改入库初检记录'
      this.inFirstDialogVisible = true
      this.selectInFirstInfoById(row.id)
    },
    handleReview (index, row) {
      this.inFirstFormTitle = '复核入库初检记录'
      this.inFirstDialogVisible = true
      this.selectInFirstInfoById(row.id)
    },
    handleInfo (index, row) {
      this.inFirstFormTitle = '入库初检记录详情'
      this.inFirstDialogVisible = true
      this.selectInFirstInfoById(row.id)
    },
    selectInFirstInfoById (id) {
      selectInFirstInfo(id).then((res) => {
        this.inFirstForm.id = res.id
        this.inFirstForm.name = res.name
        this.inFirstForm.materialNo = res.materialNo
        this.inFirstForm.spec = res.spec
        this.inFirstForm.batchNo = res.batchNo
        this.inFirstForm.manufacturer = res.manufacturer
        this.inFirstForm.arrivalDate = res.arrivalDate
        this.inFirstForm.firstDate = res.firstDate
        this.inFirstForm.firstQuantity = res.firstQuantity
        this.inFirstForm.checkSituation = res.checkSituation
        this.inFirstForm.remarks = res.remarks
        this.inFirstForm.reviewerResult = res.reviewerResult
        this.inFirstForm.taskId = res.taskId
        this.inFirstForm.type = res.type
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectInFirstList(this.searchForm).then((res) => {
        this.inFirstPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectInFirstList(this.searchForm).then((res) => {
        this.inFirstPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectInFirstList(this.searchForm).then((res) => {
        this.inFirstPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['IN_FIRST_REVIEW']) && row.status === 1) {
        return 'review'
      }
      return ''
    },
    purchaseListChange (value) {
      if (value) {
        let purchasingList = this.purchasingList.find((item) => item.id === value)
        // this.inFirstForm.name = materialPurchaseStock.productName
        // this.inFirstForm.spec = materialPurchaseStock.spec
        // this.inFirstForm.manufacturer = materialPurchaseStock.manufactor
        // console.log(purchasingList)
        this.purchasingListDetail = purchasingList.detailList
        // console.log(this.purchasingListDetail)
        this.dialogTableVisible = true
      } else {
        this.inFirstForm.name = ''
        this.inFirstForm.spec = ''
        this.inFirstForm.manufacturer = ''
      }
    },
    handleCurrentChange (val) {
      this.currentRow = val
    },
    handlerOk () {
      if (this.currentRow[0]) {
        this.dialogTableVisible = false
        this.inFirstForm.name = this.currentRow[0].materialName
        this.inFirstForm.spec = this.currentRow[0].spec
        this.inFirstForm.manufacturer = this.currentRow[0].factory
        this.inFirstForm.materialNo = this.currentRow[0].materialNo
      } else {
        this.$message({
          message: '請選擇原材料！',
          type: 'warning'
        })
      }
    }
  }
}
</script>

<style>
</style>
